import * as React from 'react';
import styled from 'styled-components';
import { IoArrowForward, IoLockClosed } from 'react-icons/io5';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { userSignInComplete, userSignInInit } from '@oysterjs/core/api/auth';
import { useAuth } from '@oysterjs/core/auth';
import { OysterLogoOld } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { DigitsInput } from '@oysterjs/ui/Form/digits';
import { TextInput } from '@oysterjs/ui/Form/text';
import { FormColumn, FormContainer } from '@oysterjs/ui/Form/builder';
import { FormRow } from '@oysterjs/ui/Form/builder';
import { Spinner } from '@oysterjs/ui/Spinner';
import { getAccountSummary } from '@oysterjs/core/api/user';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';

const SignInContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 40px 0px;
  gap: 20px;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

const SignInPane = styled.div`
  width: 50%;
  max-width: 360px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const SignInImage = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 320px;
  max-height: 400px;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const SignInPage = (props: { redirect?: string }) => {
  const history = useHistory();

  const [email, setEmail] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleLogin = () => {
    setLoading(true);
    setTimeout(
      () =>
        userSignInInit(email)
          .then(() =>
            history.push(
              `/signin?email=${encodeURIComponent(email)}${
                props.redirect ? `&redirect=${encodeURIComponent(props.redirect)}` : ''
              }`
            )
          )
          .catch((err) => setError(err.message))
          .finally(() => setLoading(false)),
      500
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <PageSection>
      <SignInContainer>
        <SignInPane>
          <h1 style={{ marginBottom: '0' }}>Sign In</h1>
          <FormContainer onSubmit={onSubmit} autoComplete="on">
            <FormRow>
              <FormColumn title="Email Address">
                <TextInput
                  type="email"
                  name="email"
                  inputMode="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <Button
                primary
                leftIcon={<IoLockClosed />}
                icon={<IoArrowForward />}
                loading={loading}
                onClick={() => handleLogin()}
              >
                Sign In
              </Button>
            </FormRow>
          </FormContainer>
          {error && <p style={{ color: '#d1344b' }}>{error}</p>}
        </SignInPane>

        <SignInImage src="/images/signin.svg" />
      </SignInContainer>
    </PageSection>
  );
};

const SignInCompletePage = (props: { email: string; redirect?: string; code?: string }) => {
  const history = useHistory();

  const [code, setCode] = React.useState(props.code);
  const [loading, setLoading] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [error, setError] = React.useState('');
  const [, setAuth] = useAuth();

  const resendCode = () => {
    setResending(true);
    setTimeout(
      () =>
        userSignInInit(props.email, props.redirect)
          .catch((err) => setError(err.message))
          .finally(() => setResending(false)),
      500
    );
  };

  const handleLogin = (code?: string) => {
    if (code === undefined || code.length !== 6) {
      return;
    }

    setLoading(true);
    setTimeout(
      () =>
        userSignInComplete(props.email, code)
          .then((res) => {
            setLoading(false);
            setAuth(res);
            history.push(props.redirect ? props.redirect : '/');
          })
          .catch((err) => {
            setLoading(false);
            setError(err.message);
          }),
      500
    );
  };

  React.useEffect(() => {
    handleLogin(props.code);
  }, [props.code]);

  return (
    <>
      <div style={{ height: '40px' }} />
      <PageSection noBorder>
        <h1>Check your email</h1>
        <p>
          We've sent a magic link to <b>{props.email}</b>. Click the link in the email to finish
          signing in, or enter the 6-digit code from the email here. The code is valid for 15
          minutes.
        </p>
      </PageSection>
      <FormContainer>
        <PageSection noBorder>
          <DigitsInput
            digits={6}
            initialDigits={props.code}
            disabled={loading}
            onInputChange={setCode}
            onComplete={handleLogin}
          />
        </PageSection>
        <PageSection>
          <ButtonContainer>
            <Button
              primary
              leftIcon={<IoLockClosed />}
              icon={<IoArrowForward />}
              onClick={() => handleLogin(code)}
              loading={loading}
            >
              Continue
            </Button>
            <Button loading={resending} onClick={resendCode}>
              Resend Email
            </Button>
          </ButtonContainer>

          {error && <p style={{ color: '#d1344b' }}>{error}</p>}
          <div style={{ height: '40px' }} />
        </PageSection>
      </FormContainer>
    </>
  );
};

const AuthSignIn = (props: { token: string; redirect?: string }) => {
  const history = useHistory();
  const [error, setError] = React.useState('');

  const handleLogin = (token: string) => {
    window.localStorage.setItem(
      'oyster_token',
      JSON.stringify({
        Token: token
      })
    );
    setTimeout(
      () =>
        getAccountSummary()
          .then((res) => {
            if (res.User) {
              window.localStorage.setItem(
                'oyster_token',
                JSON.stringify({
                  Token: token,
                  User: res.User
                })
              );
              history.push(props.redirect ? props.redirect : '/policies');
            }
          })
          .catch((err) => {
            setError(err.message);
          }),
      500
    );
  };

  React.useEffect(() => {
    handleLogin(props.token);
  }, [props.token]);

  return (
    <PageSection centered>
      <Spinner color="#333333" />
      {error && <p style={{ color: '#d1344b' }}>{error}</p>}
    </PageSection>
  );
};

const SignInCompleteWrapper = (props: {
  children: (data: {
    code?: string;
    email?: string;
    redirect?: string;
    token?: string;
  }) => JSX.Element | null;
}) => {
  // Triggers a re-render when the URL changes, but does not contain the query string
  useRouteMatch();

  const params = new URLSearchParams(window.location.search);
  const email = params.get('email') || undefined;
  const redirect = params.get('redirect') || undefined;
  const code = params.get('code') || undefined;
  const token = params.get('token') || undefined;

  return (
    <PageContainer width={800}>
      <PageSection centered>
        <OysterLogoOld scale={1.5} inline />
      </PageSection>
      <ErrorBoundary>{props.children({ code, email, redirect, token })}</ErrorBoundary>
    </PageContainer>
  );
};

export const SignIn = (): JSX.Element => (
  <SignInCompleteWrapper>
    {({ code, email, redirect, token }) => {
      if (email) {
        return <SignInCompletePage email={email} redirect={redirect} code={code} />;
      }

      if (token) {
        return <AuthSignIn token={token} redirect={redirect} />;
      }

      return <SignInPage redirect={redirect} />;
    }}
  </SignInCompleteWrapper>
);
