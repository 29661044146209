import React from 'react';
import { Cookies } from 'react-cookie';
import { userIdentify } from './api/user';
import debounce from './debounce';

/**
 * The tracking script ID used to connect this instance to our account.
 */
const PORTAL_ID = '22383747';

/**
 * Hubspot chat properties that can be accessed through the context.
 */
interface HubspotChatContextProps {
  /**
   * Manually identify the current user based on the provided information.
   */
  identify: (email: string, firstName?: string, lastName?: string) => void;
}

/**
 * Hubspot chat context which gives access to HubspotChatContextProps.
 */
export const HubspotChatContext = React.createContext<HubspotChatContextProps | null>(null);

/**
 * Provider that allows child elements to access HubspotChatContext. Also
 * initializes and renders the chat widget.
 */
export const HubspotChatContextProvider = (props: React.PropsWithChildren<unknown>) => {
  const chat = useHubspotChat(PORTAL_ID);
  return <HubspotChatContext.Provider value={chat}>{props.children}</HubspotChatContext.Provider>;
};

/**
 * Implements the interface to the Hubspot chat widget and manages its lifecycle.
 *
 * @param portalId the script ID to use to initialize Hubspot
 */
export const useHubspotChat = (portalId): HubspotChatContextProps => {
  // Indicates that the Hubspot script has loaded (not necessarily the widget)
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  // Indicates that we are manually identifying the user based on information
  // they have entered, and that this should take precedence over the absense
  // of any automatic user identification.
  const [manualIdentification, setManualIdentification] = React.useState(false);

  /**
   * Updates the Hubspot identification of the user based on a response
   * from the API server. It returns true if the identification changed
   * and false otherwise.
   */
  const updateIdentification = (email: string, token: string): boolean => {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hsConversationsSettings.identificationEmail !== email ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hsConversationsSettings.identificationToken !== token
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (email && email !== window.hsConversationsSettings.identificationEmail) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window._hsq = window._hsq || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window._hsq.push(['identify', { email }]);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (!email && email !== window.hsConversationsSettings.identificationEmail) {
        new Cookies().remove('hubspotutk');
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hsConversationsSettings.identificationEmail = email;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hsConversationsSettings.identificationToken = token;

      return true;
    }

    return false;
  };

  const trackPageView = () => {
    const path = location.pathname + location.search;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._hsq = window._hsq || [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._hsq.push(['setPath', path]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._hsq.push(['trackPageView']);
  };

  /**
   * Performs identification with the API server, using either the provided
   * credentials or falling back to the session cookie which authenticates
   * the current user. It returns true if the identification changed and false
   * otherwise.
   */
  const identify = async (
    email?: string,
    firstName?: string,
    lastName?: string,
    manual?: boolean
  ): Promise<boolean> => {
    if (!scriptLoaded) {
      return false;
    }

    try {
      // get token
      const res = await userIdentify(email, firstName, lastName);
      setManualIdentification((prev) => prev || !!manual);
      return updateIdentification(res.Email, res.Token);
    } catch (e) {
      // Only reset the identification if the user was not identified manually
      if (manualIdentification || manual) {
        return false;
      }

      return updateIdentification('', '');
    }
  };

  /**
   * Resets the widget state and reloads it. Call this when the identification
   * changes in order to reflect the latest state. This function is debounced
   * so that the latest state is always available and to respect the 1-second
   * throttle that the Hubspot SDK applies.
   */
  const refreshWidget = debounce(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.HubSpotConversations.clear({ resetWidget: true });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.HubSpotConversations.widget.load();
  }, 500);

  /**
   * Effect that controls the initial load of the hook. It adds the
   * Hubspot script tag and sets `scriptLoaded` to `true` when the
   * script finishes loading and is ready.
   */
  React.useEffect(() => {
    // Set HubSpot chat settings
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: '',
      identificationToken: ''
    };

    // Add event listener.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.hsConversationsOnReady = [() => setScriptLoaded(true)];

    // Create script component.
    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.defer = true;

    // Add the script to the DOM
    document.head.appendChild(script);

    // Cleanup when the component is component is unmounted
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hsConversationsOnReady = [];
      document.head.removeChild(script);
    };
  }, []);

  /**
   * Effect controls the initial load of the widget by identifying
   * the current user (using the automatic, cookie-based method) and
   * then loads the widget.
   */
  React.useEffect(() => {
    if (scriptLoaded) {
      identify().then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.HubSpotConversations.widget.load();
      });
    }
  }, [scriptLoaded]);

  /**
   * Effect controls subsequent page route changes by re-performing
   * identification and refreshing the widget if identification changes.
   */
  React.useEffect(() => {
    if (scriptLoaded) {
      identify()
        .then((updatedIdentification) => {
          if (updatedIdentification) {
            refreshWidget();
          }
        })
        .finally(trackPageView);
    }

    // This effect intentionally omits `loaded` from the array
    // so that identification and refresh only runs on subsequent
    // page changes. The effect above should take care of the
    // initial render and loading of the chat widget.
  }, [location.pathname, location.search]);

  return {
    identify: async (email, firstName, lastName) => {
      const updatedIdentification = await identify(email, firstName, lastName, true);
      if (updatedIdentification) {
        refreshWidget();
      }
    }
  };
};
