import { AccountSummary, Policy } from '@oysterjs/types';
import { Get, Post } from './base';

export const getPolicies = () => Get<{ Policies: Policy[] }>('/user/policies');

export const getPolicy = (policyId: string) =>
  Get<{ Policy: Policy }>(`/user/policies/${policyId}`);

export const getAccountSummary = (redirectUrl?: string) =>
  Get<AccountSummary>('/user/account', { redirectUrl });

export const userIdentify = (email?: string, firstName?: string, lastName?: string) =>
  Post<{ Token: string; Email: string }>(`/user/identify`, {
    Email: email,
    FirstName: firstName,
    LastName: lastName
  });
