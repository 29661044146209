import './style.scss';

import { init as initApm } from '@oysterjs/core/apm';
initApm();

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useHistory
} from 'react-router-dom';

import PolicyholderDashboardPage from './pages/PolicyholderDashboard';
import { SignIn } from './pages/SignIn';
import { useAuth } from '@oysterjs/core/auth';
import { HubspotChatContextProvider } from '@oysterjs/core/hubspot';

const Authenticated = (props: React.PropsWithChildren<unknown>) => {
  const [auth] = useAuth();
  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    if (!auth.Token && match.path !== '/signin') {
      history.push('/signin');
    }
  }, [auth.Token]);

  if (!auth.Token && match.path !== '/signin') {
    return null;
  }

  return <>{props.children}</>;
};

const App = () => {
  return (
    <HubspotChatContextProvider>
      <Switch>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/">
          <Authenticated>
            <PolicyholderDashboardPage />
          </Authenticated>
        </Route>
        <Route>
          <h1>404</h1>
        </Route>
      </Switch>
    </HubspotChatContextProvider>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
