import * as Sentry from '@sentry/react';
import { getUser } from './auth';
import config from './config';

class Apm {
  constructor(private session: Session) {}

  // eslint-disable-next-line
  captureError(error: any) {
    if (Sentry.isInitialized()) {
      Sentry.captureException(error);
    }
  }

  setUserContext(userObject: { id?: string; email?: string }) {
    if (Sentry.isInitialized()) {
      Sentry.setUser(userObject);
    }
    this.session.userId = userObject.id?.toString() || this.session.userId;
  }

  getSessionId() {
    return Sentry.getReplay()?.getReplayId();
  }
}

let apm: Apm;

interface Session {
  userId: string;
  pageLoadId: string;
  merchantId?: string;
}

const initSession = (): Session => {
  const getRandomString = (len: number): string => {
    const characters = '0123456789abcdef';
    let str = '';
    for (let i = 0; i < len; i++) {
      str += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return str;
  };

  // Check if we have an authenticated user ID
  let userId = getUser()?.ID;

  // Check if we already stored an ID locally
  if (!userId) {
    userId = window.localStorage.getItem('oyster_user_id') || undefined;
  }

  // Otherwise, generate an ID and store it
  if (!userId) {
    userId = getRandomString(24);
    window.localStorage.setItem('oyster_user_id', userId);
  }

  return {
    userId,
    pageLoadId: getRandomString(24)
  };
};

export const init = (): void => {
  const session = initSession();

  Sentry.init({
    dsn: config().secrets.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error']
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          window.location.origin,
          config().backendBaseUrl.api,
          config().backendBaseUrl.integrate,
          config().backendBaseUrl.merchant,
          config().backendBaseUrl.metrics,
          config().backendBaseUrl.statics
        ],
        networkCaptureBodies: true,
        networkRequestHeaders: ['X-Merchant-Integration-ID', 'X-Merchant-API-Key'],
        maskAllText: false,
        blockAllMedia: false
      }),
      Sentry.sessionTimingIntegration()
    ],

    autoSessionTracking: true,
    enabled: true,
    enableTracing: true,

    environment: config().environment,
    release: config().serviceVersion,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      config().backendBaseUrl.api,
      config().backendBaseUrl.integrate,
      config().backendBaseUrl.merchant,
      config().backendBaseUrl.metrics,
      config().backendBaseUrl.statics
    ],

    // Capture Replay for 100% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });

  apm = new Apm(session);
};

// eslint-disable-next-line
// @ts-ignore
export default (): Apm => apm;
