import * as Sentry from '@sentry/react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory
} from 'react-router-dom';

const Route = Sentry.withSentryRouting(BrowserRoute);

import { getPolicies, getPolicy } from '@oysterjs/core/api/user';
import { getClaim, getClaims } from '@oysterjs/core/api/claims';
import { Loadable } from '@oysterjs/ui/Loadable';
import { PageContainer } from '@oysterjs/ui/Page';
import { ClaimState } from '@oysterjs/types';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';

import { NavBar } from './navbar';

import { ClaimPage, ClaimsPage, ResumeClaimsPage } from './claims';
import { PoliciesPage } from './policies';
import { PolicyPage } from './policy';

const AsyncPoliciesPage = () => (
  <Loadable request={Promise.all([getPolicies()])}>
    {([policies]) => <PoliciesPage policies={policies.Policies} />}
  </Loadable>
);

const AsyncClaimsPage = () => (
  <Loadable request={Promise.all([getClaims(), getPolicies()])}>
    {([d, p]) => <ClaimsPage claims={d.Claims} policies={p.Policies} />}
  </Loadable>
);

const AsyncPolicyPage = ({ policyId }: { policyId: string }) => (
  <Loadable request={Promise.all([getPolicy(policyId)])}>
    {([policy]) => <PolicyPage policy={policy.Policy} />}
  </Loadable>
);

const AsyncClaimPage = ({ claimId }: { claimId?: string }) => (
  <Loadable
    request={Promise.all([claimId ? getClaim(claimId) : Promise.resolve(undefined), getPolicies()])}
  >
    {([d, p]) => <ClaimPage claim={d?.Claim} policies={p.Policies} />}
  </Loadable>
);

const AsyncResumeClaimPage = () => {
  const history = useHistory();
  return (
    <Loadable request={getClaims()}>
      {(d) => {
        const notFinished = d.Claims.filter(
          (c) => c.State != ClaimState.denied && c.State != ClaimState.settled
        );
        if (notFinished.length === 0) {
          history.push('/claims/start');
          return null;
        }

        return <ResumeClaimsPage claims={notFinished} />;
      }}
    </Loadable>
  );
};

export default (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <PageContainer>
      <NavBar />
      <ErrorBoundary>
        <Switch>
          <Route
            path={`${path.replace(/\/+$/, '')}/policies/:id`}
            render={(props) => <AsyncPolicyPage policyId={props.match?.params.id || ''} />}
          ></Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/policies`}>
            <AsyncPoliciesPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/claims`}>
            <AsyncClaimsPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/claims/start`}>
            <AsyncClaimPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/claims/resume`}>
            <AsyncResumeClaimPage />
          </Route>
          <Route
            path={`${path.replace(/\/+$/, '')}/claims/:id`}
            render={(props) => <AsyncClaimPage claimId={props.match?.params.id || ''} />}
          ></Route>
          <Redirect to={`${path.replace(/\/+$/, '')}/policies`} />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
