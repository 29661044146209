import * as React from 'react';
import styled from 'styled-components';
import { useRouteMatch, NavLink } from 'react-router-dom';

import { IoMenu } from 'react-icons/io5';
import { OysterLogoOld } from '@oysterjs/ui/Logo';

const LeftMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const NavItem = styled.li`
  padding: 18px 0px;
  display: flex;
  font-size: 1.5em;
  font-weight: 500;
  cursor: pointer;
`;

const RightMenuContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const HamburgerMenu = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  right: 0px;
  background: white;
  border-top: 2px solid #fafafa;
  border-bottom: 2px solid #f2f2f2;
  z-index: 998;
`;

const HamburgerMenuItemContainer = styled.div`
  padding: 20px 40px;
  box-sizing: border-box;
  width: 100%;
  font-size: 1em;
  font-weight: 500;

  &:hover {
    background: #fafafa;
  }
`;

const HamburgerMenuItem = (props: { label: string; link: string; onClick: () => void }) => (
  <li>
    <NavLink exact to={props.link} activeClassName="active-tab" onClick={props.onClick}>
      <HamburgerMenuItemContainer>{props.label}</HamburgerMenuItemContainer>
    </NavLink>
  </li>
);

export const MobileNavBar = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { url } = useRouteMatch();

  return (
    <>
      <LeftMenuContainer>
        <NavItem>
          <NavLink exact to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <OysterLogoOld />
          </NavLink>
        </NavItem>
      </LeftMenuContainer>
      <RightMenuContainer>
        <NavItem
          aria-haspopup
          aria-expanded={expanded}
          tabIndex={0}
          role="button"
          aria-label="Expand Menu"
          onClick={() => setExpanded((prev) => !prev)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setExpanded((prev) => !prev);
              return false;
            }
            return true;
          }}
        >
          <IoMenu
            style={{
              cursor: 'pointer',
              color: expanded ? 'white' : undefined,
              background: expanded ? '#0EA5E9' : undefined,
              borderRadius: '50%',
              padding: '10px'
            }}
          />
        </NavItem>
        <HamburgerMenu style={{ display: expanded ? 'block' : 'none' }}>
          <HamburgerMenuItem
            label="Policies"
            link={`${url.replace(/\/+$/, '')}/policies`}
            onClick={() => setExpanded(false)}
          />
          <HamburgerMenuItem
            label="Claims"
            link={`${url.replace(/\/+$/, '')}/claims`}
            onClick={() => setExpanded(false)}
          />
        </HamburgerMenu>
      </RightMenuContainer>
    </>
  );
};
