import { Comparison } from '@oysterjs/ui/Table/comparison';
import { ElectronicsType } from '@oysterjs/types';
import { BikeProduct, Policy, PolicyType, Product, ProductType } from '@oysterjs/types';

export const getPolicyDisplayName = (policy: Policy): string => {
  switch (policy.Type) {
    case PolicyType.bike:
      return 'Bike';
    case PolicyType.markelOffroad:
      return 'Offroad';
    case PolicyType.markelMotorcycle:
      return 'Motorcycle';
    case PolicyType.markelBike:
      return 'Bike';
    case PolicyType.minicoJewelry:
    case PolicyType.chubbJewelry:
      return 'Jewelry';
    case PolicyType.worthAveElectronics:
      if (
        [ElectronicsType.iPhone, ElectronicsType.smartPhone].includes(
          policy.InsuredItems[0]?.Details?.Type as ElectronicsType
        )
      ) {
        return 'Phone';
      }
      return 'Electronics';
  }
};

export const getPolicyHref = (policy: Policy): string => {
  return `/policies/${policy.ID}`;
};

export const getPolicyDisplayImage = (policyType: PolicyType, productType?: string): string => {
  switch (policyType) {
    case PolicyType.markelOffroad:
      return 'https://dashboard.withoyster.com/images/atv_insurance.svg';
    case PolicyType.markelMotorcycle:
      return 'https://dashboard.withoyster.com/images/motorcycle_insurance.svg';
    case PolicyType.bike:
    case PolicyType.markelBike:
      return 'https://dashboard.withoyster.com/images/bike_insurance.svg';
    case PolicyType.minicoJewelry:
    case PolicyType.chubbJewelry:
      return 'https://dashboard.withoyster.com/images/jewelry_insurance.svg';
    case PolicyType.worthAveElectronics:
      return productType === ProductType.phone ||
        productType === ElectronicsType.iPhone ||
        productType === ElectronicsType.smartPhone
        ? 'https://dashboard.withoyster.com/images/phone_insurance.svg'
        : 'https://dashboard.withoyster.com/images/electronics_insurance.svg';
  }
};

export const getProductDisplayName = (type: ProductType, product?: Product): string => {
  switch (type) {
    case ProductType.offroad:
      return 'ATV';
    case ProductType.motorcycle:
      return 'Motorcycle';
    case ProductType.bike:
      if (product) {
        const bikeProduct = product.Details as BikeProduct;
        if (bikeProduct.PowerSource) {
          return 'Electric Bike';
        }
      }
      return 'Bicycle';
    case ProductType.jewelry:
      return 'Jewelry';
    case ProductType.phone:
      return 'Phone';
    case ProductType.electronics:
      // TODO: use ProductType.phone type for phone product routing (asana: https://app.asana.com/0/1200762684494204/1203949127097148/f))
      if (
        product &&
        [ElectronicsType.iPhone, ElectronicsType.smartPhone].includes(
          product.Details.Type as ElectronicsType
        )
      ) {
        return 'Phone';
      }
      return 'Electronics';
  }
};

export const getProductDisplayImage = (type: ProductType, overrideUrl?: string): string => {
  if (overrideUrl) {
    return overrideUrl;
  }

  return getPolicyDisplayImage(getPolicyFromProduct(type), type);
};

export const getPolicyFromProduct = (type: ProductType): PolicyType => {
  switch (type) {
    case ProductType.offroad:
      return PolicyType.markelOffroad;
    case ProductType.motorcycle:
      return PolicyType.markelMotorcycle;
    case ProductType.bike:
      return PolicyType.markelBike;
    case ProductType.jewelry:
      return PolicyType.minicoJewelry; // default to minico for now
    case ProductType.phone:
    case ProductType.electronics:
      return PolicyType.worthAveElectronics;
  }
};

export const getProductFromPolicy = (type: PolicyType): ProductType | undefined => {
  switch (type) {
    case PolicyType.bike:
    case PolicyType.markelBike:
      return ProductType.bike;
    case PolicyType.chubbJewelry:
    case PolicyType.minicoJewelry:
      return ProductType.jewelry;
    case PolicyType.worthAveElectronics:
      return ProductType.electronics;
    default:
      return;
  }
};

export const getPolicyComparison = (type: PolicyType): Comparison => {
  switch (type) {
    case PolicyType.bike:
    case PolicyType.markelOffroad: // TODO: FIX THIS
    case PolicyType.markelMotorcycle: // TODO: FIX THIS
    case PolicyType.markelBike:
      return {
        headers: ['Oyster Insurance', 'Homeowners or renters insurance', 'Extended warranty'],
        sections: [
          {
            title: 'Coverage',
            items: [
              {
                name: 'Full-value coverage',
                enabled: [1, 0, 0]
              },
              {
                name: 'Theft',
                enabled: [1, 0.5, 0]
              },
              {
                name: 'Crash damage',
                enabled: [1, 0, 0]
              },
              {
                name: 'Damage or loss in transit',
                enabled: [1, 0, 0]
              },
              {
                name: 'Medical payments',
                enabled: [1, 0, 0]
              },
              {
                name: 'Liability protection',
                enabled: [1, 0.5, 0]
              },
              {
                name: 'eBike cover',
                enabled: [1, 0, 1]
              },
              {
                name: 'Worldwide coverage',
                enabled: [1, 0, 1]
              },
              {
                name: 'Racing coverage',
                enabled: [1, 0, 0]
              },
              {
                name: 'Replacement bike rental',
                enabled: [1, 0, 0]
              }
            ]
          },
          {
            title: 'Features',
            items: [
              {
                name: 'A.M. Best A rated policy',
                enabled: [1, 0.5, 0]
              },
              {
                name: 'No impact of claims on homeowner insurance',
                enabled: [1, 0, 1]
              },
              {
                name: 'Referral discounts',
                enabled: [1, 0, 0]
              }
            ]
          }
        ]
      };

    case PolicyType.minicoJewelry:
    case PolicyType.chubbJewelry:
      return {
        headers: ['Oyster Insurance', 'Homeowners or renters insurance', 'Extended warranty'],
        sections: [
          {
            title: 'Coverage',
            items: [
              {
                name: 'Full-value coverage',
                enabled: [1, 0, 0]
              },
              {
                name: 'Theft',
                enabled: [1, 0.5, 0]
              },
              {
                name: 'Loss',
                enabled: [1, 0, 0]
              },
              {
                name: 'Mysterious disappearance',
                enabled: [1, 0, 0]
              },
              {
                name: 'Accidental damage',
                enabled: [1, 0.5, 0.5]
              },
              {
                name: 'Worldwide coverage',
                enabled: [1, 1, 1]
              }
            ]
          },
          {
            title: 'Features',
            items: [
              {
                name: '$0 deductible',
                enabled: [1, 0, 0.5]
              },
              {
                name: 'A.M. Best A+ rated policy',
                enabled: [1, 0.5, 0]
              },
              {
                name: 'No impact of claims on homeowner insurance',
                enabled: [1, 0, 1]
              },
              {
                name: 'Referral discounts',
                enabled: [1, 0, 0]
              },
              {
                name: '30-day free cancellation',
                enabled: [1, 0, 0]
              }
            ]
          }
        ]
      };

    case PolicyType.worthAveElectronics:
      return {
        headers: ['Oyster Insurance', 'Typical Homeowners'],
        sections: [
          {
            title: 'Coverage',
            items: [
              {
                name: 'Full-value coverage',
                enabled: [1, 0]
              },
              {
                name: 'Theft',
                enabled: [1, 0.5]
              },
              {
                name: 'Loss',
                enabled: [1, 0]
              },
              {
                name: 'Accidental damage',
                enabled: [1, 0.5]
              }
            ]
          },
          {
            title: 'Features',
            items: [
              {
                name: '$0 deductible',
                enabled: [1, 0]
              },
              {
                name: 'A.M. Best A+ rated policy',
                enabled: [1, 0.5]
              },
              {
                name: 'Claims do not increase premiums',
                enabled: [1, 0]
              },
              {
                name: '30-day free cancellation',
                enabled: [1, 0]
              },
              {
                name: 'Referral discount',
                enabled: [1, 0]
              }
            ]
          }
        ]
      };
  }
};

export const getPolicyOptionalCoverageItem = (
  itemId: string
): { title: string; description: string } => {
  switch (itemId) {
    case 'coverage_medical_payments':
      return {
        title: 'Medical Payments',
        description:
          "We'll cover your medical bills up to the coverage limit if you're hurt while riding your bike."
      };
    case 'coverage_liability_protection':
      return {
        title: 'Liability Protection',
        description:
          "We'll protect you from being personally liable up to the coverage limit for any injury or damage you may cause while riding your bike."
      };
    case 'coverage_vehicle_contact_protection':
      return {
        title: 'Vehicle Contact Protection',
        description:
          "We'll protect you from damages in case you are hit by a vehicle that doesn’t carry proper insurance to cover your losses."
      };
    case 'coverage_worldwide':
      return {
        title: 'Worldwide Coverage',
        description: "We'll cover your bike wherever in the world you travel with it."
      };

    case 'coverage_extended_warranty':
      return {
        title: 'Extended Warranty',
        description:
          "For new devices, we'll extend any existing OEM's warranty and cover mechanical failures and manufacturing defects."
      };

    case 'motor_coverage_medical_payments':
      return {
        title: 'Medical Payments',
        description:
          "We'll cover up to $1k of medical bills if you're hurt while riding your vehicle."
      };
    case 'motor_coverage_comprehensive':
      return {
        title: 'Comprehensive Coverage',
        description:
          "We'll cover loss of or damage to your vehicle as a result of events like fire, theft, vandalism, or hitting an animal, subject to the deductible."
      };
    case 'motor_coverage_collision':
      return {
        title: 'Collision Coverage',
        description:
          "We'll cover damage to your vehicle as a result of a hit, crash, fall, or other collision, subject to the deductible."
      };
    case 'motor_coverage_roadside_assistance':
      return {
        title: 'Roadside Assistance',
        description:
          'Get access to our nationwide roadside assistance program, including 24-hour emergency support, supply delivery, tire and battery service, locksmith, and more.'
      };

    default:
      return {
        title: '',
        description: ''
      };
  }
};

export const getPolicyCoverageItems = (
  type: PolicyType
): Array<{ title: string; description: string }> => {
  switch (type) {
    case PolicyType.bike:
      return [
        {
          title: 'Theft',
          description: "Your bike is covered if it's stolen from or away from home."
        },
        {
          title: 'Loss',
          description: "Your bike is covered if it's lost in transport or left behind."
        },
        {
          title: 'Accidental Damage',
          description: "Your bike is covered if it's accidentally damaged."
        },
        {
          title: 'Emergency Travel',
          description: 'Your travel costs are covered if your bike suffers a covered loss.'
        },
        {
          title: 'Worldwide Coverage',
          description: 'Your bike is covered while traveling anywhere in the world.'
        },
        {
          title: 'Spare Parts and Apparel',
          description: 'Spare parts and cycle apparel are also covered from theft and damage.'
        }
      ];

    case PolicyType.markelBike:
      return [
        {
          title: 'Bikes and eBikes',
          description: 'Bikes and eBikes (class 1, 2, and 3) are fully covered on this policy.'
        },
        {
          title: 'Full Value Coverage',
          description: 'Your bike is covered for its full replacement value.'
        },
        {
          title: 'Theft',
          description: 'Your bike is covered if it is stolen from or away from home.'
        },
        {
          title: 'Damage or Loss in Transit',
          description: 'Your bike is covered if it is lost or damaged in transit by third parties.'
        },
        {
          title: 'Crash Damage',
          description: 'Your bike is covered if it is damaged in an accident or crash.'
        },
        {
          title: 'Spare Parts and Apparel',
          description: 'Spare parts and cycle apparel are also covered from theft and damage.'
        },
        {
          title: 'Racing Coverage',
          description: 'Your coverages also apply when competing in a race or event.'
        }
      ];

    case PolicyType.markelOffroad:
    case PolicyType.markelMotorcycle:
      return [
        {
          title: 'Passenger Liability',
          description:
            "You're covered if you're required to pay for injuries to passengers up to a limit."
        },
        {
          title: 'Uninsured Motorist Injury',
          description:
            "You're covered if an unininsured motorist is at-fault in an accident up to a limit."
        },
        {
          title: 'Property Damage',
          description: "You're covered if your ATV causes property damage for up to $5k."
        },
        {
          title: 'Pedestrian Injury',
          description: "You're covered if you hit or injure a pedestrian for up to $50k."
        },
        {
          title: 'Bodily Injury',
          description: "You're covered if you cause bodily injury to another person up to a limit."
        }
      ];

    case PolicyType.minicoJewelry:
    case PolicyType.chubbJewelry:
      return [
        {
          title: 'Full Value Coverage',
          description: 'Your jewelry is covered for its full-value repair or replacement value.'
        },
        {
          title: 'Theft',
          description: "Your jewelry is covered if it's stolen from or away from home."
        },
        {
          title: 'Loss and Disappearance',
          description: "Your jewelry is covered if it's lost and you cannot find it."
        },
        {
          title: 'Accidental Damage',
          description: "Your jewelry is covered if it's damaged, cracked, or it snags."
        },
        {
          title: 'Natural Disaster',
          description: 'Your jewelry is covered in events like fire, earthquake, and floods.'
        },
        {
          title: 'Worldwide Coverage',
          description: 'Your jewelry is covered while traveling anywhere in the world.'
        }
      ];

    case PolicyType.worthAveElectronics:
      return [
        {
          title: 'Full Value Coverage',
          description: 'Your device is covered for the full replacement value.'
        },
        {
          title: 'Cracked Screens',
          description: 'Your device is covered if your screen is cracked partially or entirely.'
        },
        {
          title: 'Theft',
          description: 'Your device is covered if it is stolen from your home or outside of home.'
        },
        {
          title: 'Accidental Damage',
          description: "Your device is covered if you've dropped or damaged it accidentally."
        },
        {
          title: 'Liquid Submersion',
          description:
            'Your device is covered if it malfunctions due to spills or liquid submersion.'
        },
        {
          title: 'Natural Disaster',
          description: 'Your device is covered from fire, flood, earthquake, lightning, and more.'
        },
        {
          title: 'Used and Refurbished',
          description: 'Your device is covered even if it was purchased as used or refurbished.'
        },
        {
          title: 'Worldwide Coverage',
          description: 'Your device is covered while traveling anywhere in the world.'
        }
      ];
  }
};

export const getPolicyCoverageItemsLongDescription = (
  type: PolicyType
): Array<{ title: string; description: string }> => {
  switch (type) {
    case PolicyType.markelBike:
      return [
        {
          title: 'Bikes and eBikes',
          description: 'Bikes and eBikes (class 1, 2, and 3) are fully covered on this policy.'
        },
        {
          title: 'Full Value Coverage',
          description: 'Your bike is covered for its full replacement value.'
        },
        {
          title: 'Theft',
          description:
            'Your bike is covered if it is stolen from or away from home. If away from home, it needs to be secured to an immovable object with an appropriate lock.'
        },
        {
          title: 'Damage or Loss in Transit',
          description: 'Your bike is covered if it is lost or damaged in transit by third parties.'
        },
        {
          title: 'Crash Damage',
          description: 'Your bike is covered if it is damaged in an accident or crash.'
        },
        {
          title: 'Spare Parts and Apparel',
          description:
            'Spare parts and cycle apparel are covered up to $500 each per claim and $1,000 in any policy period. This coverage is for actual cash value and not replacement value.'
        },
        {
          title: 'Racing Coverage',
          description:
            'Your coverages also apply when competing in a race or event. There is also additional cover of up to $250 for renting a temporary substitute bike if your bike is stolen or damaged while at the event, plus coverage for any non-refundable pre-paid event entry fees up to $500.'
        }
      ];

    default:
      return getPolicyCoverageItems(type);
  }
};
