import { GraphQLErrors } from '@apollo/client/errors';
import { ValidationError } from '@oysterjs/types';

export enum ErrorCode {
  unknown = '',
  unsupportedGeo = 'unsupported_geo',
  riskRejected = 'risk_rejected',
  alreadySubmitted = 'already_submitted',
  submissionFailed = 'submission_failed'
}

export enum ErrorType {
  unknown = '',

  networkError = 'js.fetch.network_error',
  unknownApiError = 'js.api.unknown_error',

  validationError = 'validation_error',
  processingError = 'processing_error',
  underwritingError = 'underwriting_error',
  preconditionFailed = 'precondition_failed'
}

export interface WrappedErrorOptions {
  code: ErrorCode;
  type: ErrorType;
  details?: string;
  metadata?: Record<string, string>;
  cause?: WrappedError | Error;
}

export class WrappedError extends Error {
  constructor(
    message: string,
    public opts: WrappedErrorOptions = { code: ErrorCode.unknown, type: ErrorType.unknown }
  ) {
    super(message);
    this.name = 'WrappedError';
  }

  type = () => {
    return this.opts.type;
  };

  code = () => {
    return this.opts.code;
  };

  details = () => {
    return this.opts.details;
  };

  cause = () => {
    return this.opts.cause;
  };

  metadata = () => {
    return this.opts.metadata || {};
  };

  getValidationError = () => {
    return {
      Field: this.opts.metadata?.Field || '',
      SubField: this.opts.metadata?.SubField || '',
      Message: this.message
    };
  };

  getGraphQLSchemaValidationError = () => {
    return {
      field: this.opts.metadata?.Field || '',
      subField: this.opts.metadata?.SubField || '',
      message: this.message
    };
  };

  // Figure out how to type this better
  // eslint-disable-next-line
  static fromApiError(err: any): WrappedError {
    const x = new WrappedError(err.Message, {
      code: err.Code || ErrorCode.unknown,
      type: err.Type || ErrorType.unknown,
      metadata: {
        Field: err.Field,
        SubField: err.SubField,
        RequestID: err.RequestID
      }
    });
    return x;
  }

  static fromValidationError(validationError: ValidationError): WrappedError {
    return new WrappedError(validationError.Message, {
      code: ErrorCode.unknown,
      type: ErrorType.validationError,
      metadata: {
        Field: validationError.Field,
        SubField: validationError.SubField || ''
      }
    });
  }

  static fromGraphQLError(errors: GraphQLErrors): WrappedError {
    return new WrappedError(errors.join(''), {
      code: ErrorCode.unknown,
      type: ErrorType.networkError
    });
  }

  // Figure out how to type this better
  // eslint-disable-next-line
  static asWrappedError(err: any): WrappedError {
    // TODO: this does not yet handle converting non-WrappedErrors
    // to WrappedError, as Javascript is weird about `instanceof`
    // mixed with catch blocks.
    return err;
  }
}
