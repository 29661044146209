import styled from 'styled-components';
import { useRouteMatch, NavLink } from 'react-router-dom';

import { OysterLogoOld } from '@oysterjs/ui/Logo';

const LeftMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const NavItem = styled.li`
  padding: 16px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  :hover {
    color: #0ea5e9;
  }

  :active {
    color: #269985;
  }
`;

export const DesktopNavBar = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <LeftMenuContainer>
        <NavItem>
          <NavLink exact to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <OysterLogoOld />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/policies`} activeClassName="active-tab">
            Policies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/claims`} activeClassName="active-tab">
            Claims
          </NavLink>
        </NavItem>
      </LeftMenuContainer>
    </>
  );
};
