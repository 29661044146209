import config from './config';

const getStaticFileUrl = (path: string): string =>
  `${config().backendBaseUrl.statics}/${path.replace(/^\/+/, '')}`;

export const getDocumentUrl = (id: string): string => {
  return getStaticFileUrl(`/doc/${id}`);
};

export const getPolicyDocumentUrl = (id: string): string => {
  return getStaticFileUrl(`/policy/doc/${id}`);
};

export const getRentalDocumentUrl = (id: string): string => {
  return getStaticFileUrl(`/rental/doc/${id}`);
};
