import * as React from 'react';
import styled from 'styled-components';
import {
  IoAlertCircleSharp,
  IoBan,
  IoCloudOffline,
  IoDownload,
  IoHelp,
  IoHourglass,
  IoPencilSharp,
  IoShieldCheckmark,
  IoTime
} from 'react-icons/io5';

import { PageSection } from '@oysterjs/ui/Page';
import { getPolicyDisplayName } from '@oysterjs/core/policy';
import { Policy, PolicyState } from '@oysterjs/types';
import { Redirect, Route as BrowserRoute, Switch, useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { NavLink } from 'react-router-dom';
import { getPolicyDocumentUrl } from '@oysterjs/core/statics';
import { BoxContainer, BoxItem } from '@oysterjs/ui/Box';

export const getPolicyStatus = (
  policy: Policy
): {
  noDocument?: boolean;
  backgroundColor: string;
  badgeColor: string;
  badge: JSX.Element;
  description: JSX.Element;
  premiumText: JSX.Element;
  stateText: string;
  buttonText?: string;
} => {
  switch (policy.State) {
    case PolicyState.unknown:
      return {
        noDocument: true,
        backgroundColor: '#e6e6e6',
        badgeColor: 'rgba(0, 0, 0, 0.5)',
        badge: <IoPencilSharp />,
        description: (
          <>
            Your application was <b>started</b> but not completed.
          </>
        ),
        premiumText: <>Your premium will be determined after you submit your application.</>,
        stateText: 'In Progress',
        buttonText: 'Continue Application'
      };

    case PolicyState.offerSubmitted:
      return {
        noDocument: true,
        backgroundColor: 'rgba(252, 227, 193)',
        badgeColor: 'rgba(214, 131, 0)',
        badge: <IoAlertCircleSharp style={{ color: 'rgba(214, 131, 0)' }} />,
        description: (
          <>
            We need <b>more information</b> to process your policy.
          </>
        ),
        premiumText: <>Your payment will be processed when your policy activates.</>,
        stateText: 'Pending Submission',
        buttonText: 'Finish Application'
      };

    case PolicyState.applicationSubmitted:
    case PolicyState.policyBinding:
      if (policy.Coverage.ActivationTrigger && policy.InsuredItems.some((i) => i.HasNotReceived)) {
        return {
          noDocument: true,
          backgroundColor: 'rgba(252, 227, 193)',
          badgeColor: 'rgba(214, 131, 0)',
          badge: <IoAlertCircleSharp style={{ color: 'rgba(214, 131, 0)' }} />,
          description: (
            <>
              Your application has been <b>submitted</b>, but it has not been <b>activated</b>.
            </>
          ),
          premiumText: <>Your payment will be processed when your policy activates.</>,
          stateText: 'Pending Activation',
          buttonText: 'Activate Policy'
        };
      }
      return {
        noDocument: true,
        backgroundColor: 'rgba(178,243,252,0.4)',
        badgeColor: 'rgb(16,98,110,0.4)',
        badge: <IoHourglass style={{ color: 'rgb(16,98,110,0.4)' }} />,
        description: (
          <>
            Your policy is being <b>processed</b>. Our team is reviewing your information.
          </>
        ),
        premiumText: <>Your payment will be processed when your policy activates.</>,
        stateText: 'Processing',
        buttonText: 'View Policy'
      };

    case PolicyState.policyInforce:
      return {
        backgroundColor: 'rgba(189, 234, 205)',
        badgeColor: 'rgba(57, 145, 88)',
        badge: <IoShieldCheckmark style={{ color: 'rgba(57, 145, 88)' }} />,
        description: (
          <>
            Your policy is <b>active</b> and your items are protected.
          </>
        ),
        premiumText: (
          <>
            Your policy is effective until <b>{new Date(policy.ExpiresAt).toLocaleDateString()}</b>.
          </>
        ),
        stateText: 'Active',
        buttonText: 'View Policy'
      };

    case PolicyState.policyCanceled:
      return {
        backgroundColor: 'rgb(255, 193, 185)',
        badgeColor: 'rgb(201, 67, 48)',
        badge: <IoBan style={{ color: 'rgb(201, 67, 48)' }} />,
        description: (
          <>
            Your policy has been <b>canceled</b>. Contact support for more information.
          </>
        ),
        premiumText: <>You won't be charged anymore.</>,
        stateText: 'Canceled',
        buttonText: 'View Policy'
      };

    case PolicyState.policyExpired:
      return {
        backgroundColor: '#e6e6e6',
        badgeColor: 'rgba(0, 0, 0, 0.5)',
        badge: <IoTime style={{ color: 'rgba(0, 0, 0, 0.5)' }} />,
        description: (
          <>
            Your policy has <b>expired</b>. Contact support for more information.
          </>
        ),
        premiumText: <>You won't be charged anymore.</>,
        stateText: 'Expired',
        buttonText: 'View Policy'
      };

    default:
      return {
        backgroundColor: '#e6e6e6',
        badgeColor: 'rgba(0, 0, 0, 0.5)',
        badge: <IoHelp />,
        description: <>There's an issue with your policy. Contact support for more information.</>,
        premiumText: <>You won't be charged anymore.</>,
        stateText: 'Unknown',
        buttonText: 'View Policy'
      };
  }
};

const PolicyDocument = styled.a`
  display: block;
  border: 1px solid rgba(33, 160, 253, 0.2);
  background: rgba(33, 160, 253, 0.2);
  color: rgba(0, 110, 191, 0.75);
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(33, 160, 253, 0.33);
    background: rgba(33, 160, 253, 0.33);
    color: rgba(0, 110, 191, 0.83);
  }

  &:active {
    border: 1px solid rgba(33, 160, 253, 0.5);
    background: rgba(33, 160, 253, 0.5);
    color: rgba(0, 110, 191, 1);
  }

  &:visited {
    color: rgba(0, 110, 191, 0.75);
  }
`;

const PolicyDocumentNotAvailable = styled.div`
  border: 1px dashed #d2d2d2;
  background: #eaeaea;
  color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
`;

const PolicyOverview = (props: { policy: Policy }): JSX.Element => {
  const [policy, setPolicy] = React.useState(props.policy);
  const status = getPolicyStatus(policy);

  React.useEffect(() => {
    setPolicy(policy);
  }, [props.policy]);

  return (
    <>
      <BoxContainer>
        <BoxItem
          title="Policy Document"
          description={
            status.noDocument
              ? "We're still working on your application. Hang tight!"
              : 'View your full policy document.'
          }
        >
          {status.noDocument ? (
            <PolicyDocumentNotAvailable>
              <IoCloudOffline />{' '}
            </PolicyDocumentNotAvailable>
          ) : (
            <PolicyDocument target="_blank" href={getPolicyDocumentUrl(policy.ID)}>
              <IoDownload />
            </PolicyDocument>
          )}
        </BoxItem>
      </BoxContainer>
    </>
  );
};

const PolicyNavigationContainer = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0 0 0 0;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  box-shadow: 0 1px 0 0 #f6f6f6;
`;

const PolicyNavigationItemContainer = styled.li`
  display: block;
  text-align: center;

  a,
  a:visited {
    display: block;
    height: 100%;
    padding: 0px 0px 10px 0px;
    box-sizing: border-box;
    font-size: 0.9em;
    color: #666666;
    text-decoration: none;
  }

  a.active {
    color: #0ea5e9;
    font-weight: bold;
    box-shadow: 0 2px 0 0 #0ea5e9;
  }
`;

export const PolicyPage = (props: { policy: Policy }) => {
  const { url, path } = useRouteMatch();
  const [policy, setPolicy] = React.useState(props.policy);

  React.useEffect(() => {
    setPolicy(props.policy);
  }, [props.policy]);

  return (
    <PageSection>
      <h1>{getPolicyDisplayName(policy)} Insurance</h1>
      <nav>
        <PolicyNavigationContainer>
          <PolicyNavigationItemContainer>
            <NavLink exact to={`${url.replace(/\/+$/, '')}/overview`}>
              Overview
            </NavLink>
          </PolicyNavigationItemContainer>
        </PolicyNavigationContainer>
      </nav>
      <div style={{ padding: '10px 0px 0px 0px' }}>
        <Switch>
          <Route path={`${path.replace(/\/+$/, '')}/overview`}>
            <PolicyOverview policy={policy} />
          </Route>
          <Redirect to={`${path.replace(/\/+$/, '')}/overview`}></Redirect>
        </Switch>
      </div>
    </PageSection>
  );
};
