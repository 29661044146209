import { PageSection } from '@oysterjs/ui/Page';
import { Policy } from '@oysterjs/types';
import { getPolicyDisplayImage, getPolicyDisplayName, getPolicyHref } from '@oysterjs/core/policy';
import { Badge } from '@oysterjs/ui/Badge';
import { IoArrowForward } from 'react-icons/io5';
import { getPolicyStatus } from './policy';
import { ButtonLink } from '@oysterjs/ui/Button';
import {
  Box,
  BoxBadgeHeader,
  BoxItemImage,
  AlternateBoxTitle,
  LeftAlignBoxContents,
  BoxContainer
} from '@oysterjs/ui/Box';

const getPolicyBadge = (policy: Policy): JSX.Element => {
  const status = getPolicyStatus(policy);
  return <Badge icon={status.badge} color={status.backgroundColor} label={status.stateText} />;
};

const getProductTypeFromPolicy = (policy: Policy): string => {
  return policy.InsuredItems[0]?.Details?.Type;
};

export const PoliciesPage = ({ policies }: { policies: Policy[] }): JSX.Element => {
  return (
    <>
      <PageSection noBorder>
        <h1>Your Policies</h1>
        {policies.length === 0 && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src="/images/no_policies.svg"
              style={{ width: '35%', minWidth: '240px' }}
              alt="No policies"
            />
            <p style={{ color: '#999999' }}>You don't have any policies yet.</p>
          </div>
        )}
        {policies.length > 0 && (
          <BoxContainer>
            {policies.map((policy) => (
              <Box className="policy-box-item">
                <BoxBadgeHeader>{getPolicyBadge(policy)}</BoxBadgeHeader>
                <BoxItemImage
                  src={getPolicyDisplayImage(policy.Type, getProductTypeFromPolicy(policy))}
                />
                <AlternateBoxTitle>{getPolicyDisplayName(policy) + ' Insurance'}</AlternateBoxTitle>
                <div style={{ flex: '1' }}>
                  <LeftAlignBoxContents>
                    <div style={{ color: '#000000', fontSize: '0.8em' }}>
                      {getPolicyStatus(policy).description}
                    </div>
                    <ButtonLink primary href={getPolicyHref(policy)} icon={<IoArrowForward />}>
                      {getPolicyStatus(policy).buttonText}
                    </ButtonLink>
                  </LeftAlignBoxContents>
                </div>
              </Box>
            ))}
          </BoxContainer>
        )}
      </PageSection>
    </>
  );
};
